import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../wrappers';
import { UserProfileContext } from '../../common/utils/getContext';

const MobMenu = ({
    expanded,
    removeMenu,
    styles,
    children,
    className
}) => {
    const menuClick = useCallback((e) => e.stopPropagation(), []);
    const { adminPage, officePage } = useContext(UserProfileContext) || {};

    return (
        <Div
            styles={{
                'pointer-events': 'none',
                position: 'fixed',
                top: '0',
                width: '100%',
                height: '100%',
                'z-index': '999',
                transition: 'all .3s ease-in'
            }}
            style={{
                ...styles.MenuBlockStyles,
                ...expanded ?
                    {
                        transform: 'translateX(0)',
                        'z-index': '9999',
                        'pointer-events': 'auto'
                    }
                    : {
                        transform: officePage || adminPage ? 'translateX(-100%)' : 'translateX(100%)',
                        'pointer-events': 'none',
                        'z-index': '-1'
                    }
            }}
            className={className}
            data-uitest='49_uitest' onClick={() => removeMenu(false) || {}}
        >
            <Div
                styles={styles.MenuStyles}
                data-uitest='50_uitest' onClick={menuClick}
            >
                {children}
            </Div>
        </Div>
    );
};

export default MobMenu;